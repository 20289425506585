export const meta: BlockMeta = {
  title: "People list",
  description: "",
  keywords: ["people", "staff"],
  icon: "groups",
  cache: false,
  tags: ["root"],
}

import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { defineBlock } from "eddev/blocks"
import { usePerson } from "@hooks/queries"
import { useNav } from "@hooks/useNav"
import { CLOSE, PLUS, LINKOUT } from "@components/atoms/Icons"
import { usePersonPopout } from "@hooks/usePersonPopout"
import { LogoMark } from "@components/atoms/Logo"
import { PersonFragment } from "../../types.graphql"
import { Link } from "eddev/routing"
import { Button } from "@components/atoms/Button"
import { useEffect, useRef, useState } from "react"

export default defineBlock("people/people-list", (props) => {
  return (
    <>
      <div
        className={`grid-auto w-full oversize border-t ${env.admin ? `max-w-full items-start !m-0` : `items-center`}`}
      >
        <div className="col-span-12">
          <h2 className="px-5 md:px-6 pt-6 pb-10 type-title-l-light md:type-title-xxl-light">Our People</h2>
        </div>

        {props.peoples?.nodes && (
          <div className="col-span-12">
            <div className="w-full grid-auto md:flex md:flex-wrap m-0 md:gap-x-6 md:gap-y-7 mb-10 md:px-6">
              {props.peoples?.nodes
                .filter((person) =>
                  person.terms?.nodes.some((term) => term.slug === "staff" || term.slug === "engineers"),
                )
                .map((person, i) => (
                  <div className="col-span-12 md:w-1/4 md:max-w-[178px]" key={i}>
                    <PersonListing person={person} />
                  </div>
                ))}

              <div className="col-span-12 md:hidden">
                <div className="w-full flex flex-col border-t md:hidden px-5">
                  <Link className="w-full py-5 flex justify-between cursor-pointer" href="/company/careers/">
                    <div className="flex gap-4">
                      <div className="w-[56px] h-0 relative overflow-hidden pt-[56px] bg-blue flex items-center justify-center bevel-empty" />
                      <div className="flex flex-col gap-2">
                        <h3 className="type-body-m-regular" dangerouslySetInnerHTML={{ __html: "Join our team" }} />
                        <p
                          className="type-ui-xs-caps"
                          dangerouslySetInnerHTML={{ __html: "Discover our current offerings" }}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="hidden md:block md:w-1/4 max-w-[178px]">
                <Link className="flex flex-col w-full group appearance-none" href="/company/careers/">
                  <div className="w-full h-0 relative overflow-hidden pt-[100%] bg-blue flex items-center justify-center bevel-empty"></div>
                  <h3 className="type-title-s-light py-3" dangerouslySetInnerHTML={{ __html: "Join our team" }} />
                  <p
                    className="type-ui-xs-caps"
                    dangerouslySetInnerHTML={{ __html: "Discover our current offerings" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        )}

        {props.peoples?.nodes && (
          <>
            <div className="col-span-12 border-t md:border-none">
              <h3 className="px-5 md:px-6 pt-6 pb-10 type-title-l-light">Board</h3>
            </div>
            <div className="col-span-12">
              <div className="w-full grid-auto md:flex md:flex-wrap m-0 md:gap-x-6 md:gap-y-7 mb-10 md:px-6">
                {props.peoples?.nodes
                  .filter((person) => person.terms?.nodes.some((term) => term.slug === "board"))
                  .map((person, i) => (
                    <div className="col-span-12 md:w-1/4 md:max-w-[178px]" key={i}>
                      <PersonListing person={person} board />
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
})

export const PersonListing = ({ person, board }: { person: PersonFragment; board?: boolean }) => {
  const personPopout = usePersonPopout()
  const contentRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [height, setHeight] = useState<number | null>(null)

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.scrollHeight)
    }
  }, [contentRef.current])

  return (
    <>
      <div className="w-full flex flex-col border-t md:hidden px-5 md:px-6 relative">
        {board && person.peopleDetail?.linkedin && (
          <Link
            href={person.peopleDetail.linkedin}
            target="_blank"
            className="absolute top-0 left-0 w-full h-full z-10"
          />
        )}

        <div
          className="w-full py-5 flex justify-between cursor-pointer"
          onClick={() => {
            if (!board) setOpen(!open)
          }}
        >
          <div className="flex gap-4">
            {person.peopleDetail?.featuredImage ? (
              <div className="bevel-image relative w-[56px] h-[56px]">
                {person.peopleDetail?.hoverImage && (
                  <div
                    className={`${open ? `opacity-100` : `opacity-0`} absolute w-[56px] h-[56px] transition-all ease-in duration-200 z-10`}
                  >
                    <ResponsiveImage {...person.peopleDetail.hoverImage} aspect={1} />
                  </div>
                )}

                <ResponsiveImage {...person.peopleDetail?.featuredImage} aspect={1} />
              </div>
            ) : (
              <div className="w-[56px] h-0 relative overflow-hidden pt-[56px] bg-greyDark flex items-center justify-center bevel-empty" />
            )}
            <div className="flex flex-col gap-2">
              <h3 className="type-body-m-regular text-left" dangerouslySetInnerHTML={{ __html: person.title || "" }} />
              <p className="type-ui-xs-caps text-left" dangerouslySetInnerHTML={{ __html: person.subtitle || "" }} />
              <p
                className="type-ui-xs-caps text-left"
                dangerouslySetInnerHTML={{ __html: person.peopleDetail?.location || "" }}
              />
            </div>
          </div>
          <i
            className={`w-8 min-w-8 max-w-8 flex-grow min-h-8 flex items-center transition-all ease-in duration-200 ${open ? `rotate-45` : ``}`}
          >
            {board && person.peopleDetail?.linkedin && <>{LINKOUT}</>}
            {!board && <>{PLUS}</>}
          </i>
        </div>

        <div
          className={`w-full transition-all ease-in duration-200 overflow-hidden flex flex-col gap-6`}
          ref={contentRef}
          style={{
            height: `auto`,
            maxHeight: `${open ? `${height}px` : 0}`,
          }}
        >
          <div className="type-body-m-regular" dangerouslySetInnerHTML={{ __html: person.bio ?? "" }} />
          {person?.peopleDetail?.email && (
            <Button target="_blank" href={`mailto:${person.peopleDetail.email}`}>
              Email
            </Button>
          )}

          {person?.peopleDetail?.linkedin && (
            <Button href={`${person.peopleDetail.linkedin}`} target="_blank">
              LinkedIn
            </Button>
          )}

          {person?.peopleDetail?.cv?.mediaItemUrl && (
            <Button href={`${person.peopleDetail.cv.mediaItemUrl}`} target="_blank">
              CV
            </Button>
          )}
          <div className="w-full h-6" />
        </div>
      </div>

      <button
        className="hidden md:flex flex-col w-full group appearance-none relative"
        onClick={() => {
          if (!board) {
            personPopout.setOpen(true)
            personPopout.setUri(person.uri)
          }
        }}
      >
        {board && person.peopleDetail?.linkedin && (
          <Link
            href={person.peopleDetail.linkedin}
            target="_blank"
            className="absolute top-0 left-0 w-full h-full z-10"
          />
        )}
        {person.peopleDetail?.featuredImage ? (
          <div className="bevel-image relative w-full">
            {person.peopleDetail?.hoverImage && (
              <div className="opacity-0 group-hover:opacity-100 absolute w-full h-full transition-all ease-in duration-200 z-10">
                <ResponsiveImage {...person.peopleDetail.hoverImage} aspect={1} />
              </div>
            )}

            <ResponsiveImage {...person.peopleDetail?.featuredImage} aspect={1} />
          </div>
        ) : (
          <div className="w-full h-0 relative overflow-hidden pt-[100%] bg-greyDark hover:bg-grey25 transition-all ease-in duration-200 flex items-center justify-center bevel-empty" />
        )}
        <h3 className="type-title-s-light text-left py-3" dangerouslySetInnerHTML={{ __html: person.title || "" }} />
        <p className="type-ui-xs-caps text-left" dangerouslySetInnerHTML={{ __html: person.subtitle || "" }} />
        <p
          className="type-ui-xs-caps text-left"
          dangerouslySetInnerHTML={{ __html: person.peopleDetail?.location || "" }}
        />
      </button>
    </>
  )
}

export const PersonPopout = () => {
  const personPopout = usePersonPopout()
  const navStatus = useNav()
  return (
    <>
      <div
        className={`hidden sticky top-0 h-dvh min-h-dvh z-50 border-l w-0 transition-all ease-in-out duration-500 ${personPopout.open ? `opacity-100 md:block` : `opacity-0`} ${navStatus.open ? `-translate-x-px md:translate-x-0` : ``}`}
      />
      <div
        className={`sticky top-0 h-dvh min-h-dvh z-40 transition-all ease-in-out duration-500 w-0 ${personPopout.open ? `` : navStatus.open ? `-translate-x-[67vw]` : `-translate-x-[81vw]`}`}
      >
        <div
          className={`bg-hero absolute top-0 h-full transition-all ease-in-out pt-[80px] duration-500 l-0 ${personPopout.open ? `border-r` : ``} ${navStatus.open ? `w-[66vw]` : `w-[80vw]`}`}
        >
          <button
            className="absolute top-0 right-0 p-6"
            onClick={() => {
              personPopout.setOpen(false)
            }}
          >
            <i className="block w-[32px] h-[32px] [&>svg]:!w-full [&>svg]:!h-full">{CLOSE}</i>
          </button>
          <Detail />
        </div>
      </div>
    </>
  )
}

const Detail = () => {
  const personPopout = usePersonPopout()
  const person = usePerson({ uri: personPopout.uri })
  if (!person.data?.peopleBy) return <></>
  const detail = person.data.peopleBy
  return (
    <div
      className={`w-full h-full bg-hero transition-all ease-in-out duration-500 px-6 pb-6 hidden md:flex flex-col justify-between overflow-auto`}
    >
      <div className="grid-auto w-full gap-6">
        <div className="col-span-6 lg:col-span-5 pb-6">
          {detail?.peopleDetail?.featuredImage ? (
            <ResponsiveImage {...detail.peopleDetail.featuredImage} aspect={1} />
          ) : (
            <div
              className={`w-full h-0 relative overflow-hidden pt-[100%] bg-hero25 flex items-center justify-center bevel-empty transition-all ease-in-out duration-500`}
            />
          )}
        </div>
        <div className="col-span-6 lg:col-start-7 flex flex-col pb-6">
          <h2
            className="type-title-xl-medium text-left w-full"
            dangerouslySetInnerHTML={{ __html: detail?.title || "" }}
          />
          <h3
            className="type-title-xl-light text-left w-full"
            dangerouslySetInnerHTML={{ __html: detail?.subtitle || "" }}
          />
          <div
            className="type-body-m-regular mt-8 text-left w-full"
            dangerouslySetInnerHTML={{ __html: detail?.bio ?? "" }}
          />
        </div>
      </div>

      <div className="grid-auto w-full gap-6">
        <div className="col-span-12">
          {detail?.peopleDetail?.email && (
            <>
              <h5 className="type-ui-xs-caps py-1 border-t">Contact</h5>

              <Link
                className="type-title-l-light block mt-4"
                target="_blank"
                href={`mailto:${detail.peopleDetail.email}`}
              >
                {detail.peopleDetail.email}
              </Link>
            </>
          )}
        </div>

        <div className="col-span-12 flex gap-3">
          {detail?.peopleDetail?.email && (
            <Button target="_blank" href={`mailto:${detail.peopleDetail.email}`}>
              Email
            </Button>
          )}
          {detail?.peopleDetail?.linkedin && (
            <Button href={`${detail.peopleDetail.linkedin}`} target="_blank">
              LinkedIn
            </Button>
          )}

          {detail?.peopleDetail?.cv?.mediaItemUrl && (
            <Button href={`${detail.peopleDetail.cv.mediaItemUrl}`} target="_blank">
              CV
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
